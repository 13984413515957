<!--
 * @Description: 设计方案
 * @Author: zhang zhen
 * @Date: 2023-02-16 09:14:16
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-02-16 10:06:07
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/designScheme.vue
-->
<template>
  <div class="DesignScheme">
    <h2 class="DesignScheme-title">设计方案</h2>
    <div class="DesignScheme-content">
      <h3 class="header-title">基本信息</h3>
      <div class="infoCard">
        <div class="infoCard-tagName">图片</div>
        <img src="~@/assets/369x242.png" alt="" />
        <div class="btn-area">
          <a-button icon="download">下载</a-button>
          <a-button type="primary" icon="search">预览</a-button>
        </div>
      </div>

      <div class="infoCard">
        <div class="infoCard-tagName">文档</div>
        <img src="~@/assets/369x242.png" alt="" />
        <div class="btn-area">
          <a-button icon="download">下载</a-button>
          <a-button type="primary" icon="search">预览</a-button>
        </div>
      </div>
    </div>
    <div class="DesignScheme-content">
      <h3 class="header-title">详细要求</h3>
      <a-form-model class="basicInfo-form">
        <a-row :gutter="80" class="basicInfo-form-item">
          <a-col :span="6">
            <a-form-model-item label="盒型">
              飞机盒
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="运输方式">
              物流
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="品牌">
              玖龙
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="级别">
              AAA
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="瓦楞层数">
              3层
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="瓦楞楞型">
              A
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="水印">
              1C
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
              <a-form-model-item label="产品尺寸">
                238*22*134
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="产品重量">
                20kg
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
                <a-form-model-item label="产品结构">
                    530*230*290mm
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
              <a-form-model-item label="模切">
                238*22*134
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
                <a-form-model-item label="静态堆码层数">
                  3
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="动态堆码层数">
                  2
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="印刷内容">
                  烫金
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="印刷大小">
                    530*230*290mm
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="印刷颜色">
                  蓝色
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="内装物">
                  洗衣机
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="内部防护">
                  防震
                </a-form-model-item>
              </a-col>
              <a-col :span="6">
                <a-form-model-item label="流通线路">
                  国内
                </a-form-model-item>
              </a-col>
          <a-col :span="6">
            <a-form-model-item label="物流监控">
              防震标签
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
              <a-form-model-item label="波浪胶">
                贴波浪胶
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="糊箱">
                是
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="管理费">
                无
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="测试标准">
                QS
              </a-form-model-item>
            </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignScheme'
}
</script>

<style lang="less" scoped>
.DesignScheme {
  background: #fffff8;
  padding: 20px 60px;
  border-radius: 8px;
  &-title {
    color: #66676d;
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
  }
  &-content {
    width: 993px;
    border-radius: 8px;
    border: 1px dashed #bfbaba;
    padding: 40px 40px 20px;
    background: #fff;
    position: relative;
    margin: 15px auto 20px;
    .flexLayout(@justifyContent: space-around;);
    .header-title {
      position: absolute;
      font-size: 15px;
      color: #605f5f;
      left: 20px;
      top: -10px;
    }
    .infoCard {
      .flexLayout(@direction: column;);
      &-tagName {
        margin-bottom: 15px;
        color: #333;
      }
      img {
        width: 369px;
        height: auto;
        margin-bottom: 15px;
      }
      .btn-area {
        width: 369px;
        .flexLayout();
      }
    }
    .basicInfo-form {
      &:not(:last-of-type) {
        margin-bottom: 30px;
      }
      &-item {
        padding: 0 20px;
      }
    }
  }
}
</style>
